/* ------------------ */
/* Custom Properties
/* ------------------ */

/* Default smallest size */
:root {
    height:inherit;

    --clr-dark: 230 35% 7%;
    --clr-accent: 254 59% 22%;
    --clr-light: 231 77% 90%;
    --clr-white: 0 0% 100%;

    /* font sizes */
    --fs-900: clamp(5rem, 8vw + 1rem, 9.375rem);
    --fs-800: 3.5rem;
    --fs-700: 1.5rem;
    --fs-600: 1rem;
    --fs-500: 1.75rem;
    --fs-400: 0.9375rem;
    --fs-300: 1rem;
    --fs-200: 0.875rem;

    /* font-families */
    --ff-serif: "Bellefair", serif;
    --ff-sans-cond: "Barlow Condensed", sans-serif;
    --ff-sans-normal: "Barlow", sans-serif;
}

/* Tablet */
@media (min-width: 35em) {
    :root {
        /* font sizes */
        --fs-800: 5rem;
        --fs-700: 2.5rem;
        --fs-600: 1.5rem;
        --fs-400: 1rem;
    }
}

/* Larger screen */
@media (min-width: 45em) {
    :root {
        /* font sizes */
        --fs-800: 6.25rem;
        --fs-700: 3.5rem;
        --fs-600: 2rem;
        --fs-500: 1.75rem;
        --fs-400: 1.125rem;
        --fs-300: 1rem;
        --fs-200: 0.875rem;
    }
}

/* ------------------ */
/* Reset
/* ------------------ */

.resize-animation-stopper * {
animation: none !important;
transition: none !important;
}

/* Box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Reset margins */
body, .main,
h1, h2, h3, h4, h5 ,h6,
p,
figure,
picture {
    margin: 0;
}

p {
    margin-bottom: 1rem;
}

h1, h2, h3, h4, h5 ,h6, p {
    font-weight: 400;
}

/* set up the body */
.main {
    /* display: grid; */
    height: inherit;
    min-height: 100vh;
    padding: var(--fs-300);
    line-height: 1.5;
    font-family: var(--ff-sans-normal) !important;
    font-size: var(--fs-400);
    color: hsl( var(--clr-white) );
    background-color: hsl( var(--clr-dark) );
}

/* make images easier to work with */
img, picture {
    max-width: 100%;
    display: block;
}

/* make forms easier to work with */
input,
button,
textarea,
select {
    font: inherit;
}

/* remove animations for people who have turned tem off */
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

/* ------------------ */
/* Utility Classes
/* ------------------ */

.flex {
    display: flex;
    gap: var(--gap, 1rem);
}

.flex-vertical {
    flex-direction: column;
}

.grid {
    display: grid;
    gap: var(--gap, 1rem);
}

.d-block {
    display: block;
}

/*  * + * means everything that has a sibling before it */
/* *:not(:first-child) is the same */
.flow > *:where(:not(:first-child)) {
    margin-top: var(--flow-space, 1rem);
}

.container {
    padding-inline: 2em;
    max-width: 80rem;
    margin-inline: auto;
}

.grid-container {
    text-align: left;
    display: grid;
    place-items: center;
    row-gap: 10%;
    padding-inline: 1rem;;
}

.grid-container > h1 {
    text-align: center;
}

.grid-container * {
    max-width: 80ch;
}

.grid-container--home {
    padding-top: 3rem;
    padding-bottom: max(6rem, 45vh);
}

.grid-container > *:first-child {
    order: 1;
}

@media (min-width: 50em) {
    .grid-container {
        text-align: left;
        column-gap: var(--container-gap, 2rem);
        /* grid-template-columns: minmax(8rem, 1fr) minmax(0, 60rem) minmax(0, 15rem) minmax(2rem, 1fr); */
        grid-template-columns: minmax(8rem, 1fr) minmax(0, 80rem) minmax(2rem, 1fr);
    }

    .grid-container--home > *:first-child {
        grid-column: 3;
    }

    .grid-container--home > *:last-child {
        grid-column: 2;
        text-align: right;
    }

    .grid-container--home {
        align-items: end;
    }

    .grid-container--about > *:first-child {
        grid-column: 2;
    }

    .direction-ltr {
        text-align: left;
        direction: ltr;
    }

    .direction-rtl {
        text-align: right;
        direction: rtl;
    }
}

/* This is for screen readers. visually hide content for sighted users, but give descriptions for impaired users */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}

.skip-to-content {
    position: absolute;
    z-index: 9999;
    background: hsl( var(--clr-white) );
    color: hsl( var(--clr-dark) );
    padding: .5em 1em;;
    margin-inline: auto;
    transform: translateY(-200%);
    transition: transform 250ms ease-in;
}

.skip-to-content:focus {
    transform: translateY(0);
}

/* colours */
.bg-dark {background-color: hsl( var(--clr-dark) )}
.bg-light {background-color: hsl( var(--clr-light) )}
.bg-white {background-color: hsl( var(--clr-white) )}

.text-dark {color: hsl( var(--clr-dark) )}
.text-accent {color: hsl( var(--clr-accent ))}
.text-light {color: hsl( var(--clr-light) )}
.text-white {color: hsl( var(--clr-white) )}

/* typography */

.ff-serif { font-family: var(--ff-serif); }
.ff-sans-cond { font-family: var(--ff-sans-cond); }
.ff-sans-normal { font-family: var(--ff-sans-normal); }

.letter-spacing-1 { letter-spacing: 4.75px; }
.letter-spacing-2 { letter-spacing: 2.7px; }
.letter-spacing-3 { letter-spacing: 2.35px; }

.uppercase { text-transform: uppercase; }

.fs-900 { font-size: var(--fs-900); }
.fs-800 { font-size: var(--fs-800); }
.fs-700 { font-size: var(--fs-700); }
.fs-600 { font-size: var(--fs-600); }
.fs-500 { font-size: var(--fs-500); }
.fs-400 { font-size: var(--fs-400); }
.fs-300 { font-size: var(--fs-300); }
.fs-200 { font-size: var(--fs-200); }

.fs-900,
.fs-800,
.fs-700,
.fs-600 {
    line-height: 1.1;
}

.numberedTitleColor {
    color: hsl( var(--clr-white) / .25)
}

/* ------------------ */
/* Components
/* ------------------ */

.large-button {
    font-size: 2rem;
    position: relative;
    display: inline-grid;
    z-index: 1;
    padding: 0 2em;
    aspect-ratio: 1 / 1; /* turns it into a square */
    border-radius: 50%;
    place-items: center;
    text-decoration: none;
}

.large-button::after {
    content: "";
    position: absolute;
    background-color: hsl( var(--clr-white) / .15);
    width: 100%;
    height:100%;
    border-radius: inherit;
    z-index: -1;
    transition: opacity 500ms linear, transform 500ms ease-in-out;
    opacity: 0;
}

.large-button:hover::after,
.large-button:focus::after {
    opacity: 1;
    transform: scale(1.5);
}

.primary-header {
    justify-content: end;
    max-height: 75px;
}

.underline-indicators > * {
    cursor: pointer;
    padding: var(--underline-gap, 1em) 0;
    border: 0;
}

.underline-indicators > *:hover,
.underline-indicators > *:focus {
    border-bottom: 0.2rem solid;
    border-color: hsl(var(--clr-white) /.5);
}

.underline-indicators > .active,
.underline-indicators > [aria-selected="true"] {
    border-bottom: 0.2rem solid;
    border-color: hsl(var(--clr-white) / 1);
}

.mobile-nav-toggle {
    display: none;
    transition: opacity 500ms linear, transform 500ms ease-in-out;
}

.primary-navigation {
    --gap: clamp(1.5rem, 5vw, 3.5rem);
    --underline-gap: 2rem;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 0rem;
    /* background: hsl( var(--clr-white) / .95); */
    transition: opacity 500ms linear, transform 500ms ease-in-out;
}

.primary-navigation > li {
    font-size: var(--fs-400);
}

.primary-navigation a {
    text-decoration: none;
}

.primary-navigation a > span{
    font-weight: 700;
    margin-right: 0.5em;
}

@supports (backdrop-filter: blur(1rem)) {
    .primary-navigation {
        /* background: hsl( var(--clr-white) / .05); */
        backdrop-filter: blur(1rem);
    }
}

@media (max-width: 35rem) {
    .primary-navigation {
        --underline-gap: .5rem;
        position: fixed;
        z-index: 1000; /* force it to the top of the stack */
        inset: 0 0 0 20%;
        list-style: none;
        padding: min(6rem, 20vh) 2rem;
        margin: 0;
        flex-direction: column;
        border-left: 2px solid hsl( var(--clr-accent ));
        transform: translateX(0);
    }

    .primary-navigation.underline-indicators > * {
        border: 0;
    }

    .primary-navigation > li {
        font-size: var(--fs-700);
    }

    .menu-open {
        transform: translateX(100%);
    }

    .mobile-nav-toggle {
        display: block;
        position: absolute;
        z-index: 2000;
        right: 1rem;
        top: 2rem;
        background: transparent;
        background-repeat: no-repeat;
        width: 1.5rem;
        aspect-ratio: 1;
        border: 0;
        transition: transform 250s ease-in-out;
    }

    .display-open-button {
        transform: rotate(0deg);
        background-image: url(./images/shared/icon-hamburger.svg); 
        transition: transform 250s ease-in-out;
    }

    .display-close-button {
        transform: rotate(00deg);
        background-image: url(./images/shared/icon-close.svg); 
        transition: transform 250s ease-in-out;
    }
}

@media (min-width:35rem) and (max-width:45em) {
    .primary-navigation {
        padding-inline: 4rem;;
    }

    .numbered-title > span {
        display: none;
    }
}

.tab-list {
    --gap: 2rem;
}

.dot-indicators > * {
    cursor: pointer;
    border: none;
    padding: 0 .5em;
    aspect-ratio: 1 / 1; /* turns it into a square */
    border-radius: 50%;
    background-color: hsl( var(--clr-white) / .25);
}

.dot-indicators > *:hover,
.dot-indicators > *:focus {
    background-color: hsl( var(--clr-white) / .5);
}

.dot-indicators > [aria-selected="true"] {
    background-color: hsl( var(--clr-white) );
}

.numbered-indicators {
    width:50px;
}

.numbered-indicators > * {
    font-size: var(--fs-400);
    font-weight: 600;
    cursor: pointer;
    border: 2px solid hsl( var(--clr-white) / .25);
    width: 5rem;
    padding: 0 .5em;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: hsl( var(--clr-dark) );
}

.numbered-indicators > *:hover,
.numbered-indicators > *:focus {
    border-color: hsl( var(--clr-white) / .5);
}

.numbered-indicators > [aria-selected="true"] {
    color: hsl( var(--clr-dark) );
    background-color: hsl( var(--clr-white) );
    border-color: hsl( var(--clr-white) );
}

/* ------------------ */
/* Page Specific backgrounds
/* ------------------ */

.main {
    background-size: cover;
    background-position: right;
}

.grid-body-content {
    background-color: hsl( var(--clr-white) / .8);
    border-radius: 10px;
    padding: 10px;
    margin-top: 50px;
}

@media (min-width:35rem) and (max-width:50em) {
    .grid-body-content {
        margin-top: 20px;
    }
}

@media (min-width:50rem) {
    .grid-body-content {
        margin-top: 0px;
    }
}

/* home */

.home {
    background-image: url(./images/home_background_mobile.jpg);
}

@media (min-width:50rem) {
    .home {
        background-position: top left;
        background-image: url(./images/home_background_main2.jpg);
    }
}

/* about */
.about {

    background-image: url(./images/home_background_mobile.jpg);
}

@media (min-width:50rem) {
    .about {
        background-position: top left;
        background-image: url(./images/home_background_main2.jpg);
    }

    .grid-container--about {
        margin-top: 4rem;
    }
}


.image-suki {
    border-radius: 10px;
    width:80%;
    margin-left: auto;
    margin-right: auto;

}

@media (min-width:35rem) and (max-width:50em) {
    .image-suki {
        margin-top: 20px;
    }
}

@media (min-width:50rem) {
    .image-suki {
        margin-top: 0px;
    }
}